import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showIntro: true,
  showResult: false,
  showTrivia: false,
  showSurvey: false,
  score: 0,
  time: 30,
  currentQuestion: 0,
  currentSurvey: 0,
  currentSurveyQuestion: 0,
  correctAnswers: [],
  selectedAnswers: [],
  chosenProducts: [],
  chosenAnswer: [],
  userSegment: {},
};

export const gameSlice = createSlice({
  name: 'games',
  initialState,
  reducers: {
    setShowIntro: (state, action) => {
      state.showIntro = action.payload;
    },

    setShowResult: (state, action) => {
      state.showResult = action.payload;
    },

    setShowTrivia: (state, action) => {
      state.showTrivia = action.payload;
    },

    setShowSurvey: (state, action) => {
      state.showSurvey = action.payload;
    },

    setScore: (state, action) => {
      state.score += action.payload;
    },

    setQuestionTime: (state, action) => {
      state.time = action.payload;
    },

    questionTimeReducer: (state) => {
      if (state.time >= 1) {
        state.time -= 1;
      }
    },

    setCurrentQuestion: (state, action) => {
      state.currentQuestion = action.payload;
    },

    setCurrentSurvey: (state, action) => {
      state.currentSurvey = action.payload;
    },

    setCurrentSurveyQuestion: (state, action) => {
      state.currentSurveyQuestion = action.payload;
    },

    setCorrectAnswers: (state, action) => {
      state.correctAnswers.push(action.payload);
    },

    setSelectedAnswers: (state, action) => {
      state.selectedAnswers.push(action.payload);
    },

    setChosenProducts: (state, action) => {
      state.chosenProducts.push(...action.payload);
    },

    setChosenAnswer: (state, action) => {
      state.chosenAnswer.push(...action.payload);
    },

    setUserSegment: (state, action) => {
      state.userSegment = action.payload;
    },

    resetGame: () => initialState,
  },
});

export const {
  setShowIntro,
  setShowResult,
  setShowTrivia,
  setShowSurvey,
  setScore,
  setQuestionTime,
  questionTimeReducer,
  setCurrentQuestion,
  setCurrentSurvey,
  setCurrentSurveyQuestion,
  setCorrectAnswers,
  setSelectedAnswers,
  setChosenProducts,
  setChosenAnswer,
  setUserSegment,
  resetGame,
} = gameSlice.actions;

export default gameSlice.reducer;
