// Import icons, images
import logo from '../assets/images/logo.svg';

// Import animations
import { motion } from 'framer-motion';
import { child } from '../utils/animations';

const Prizes = ({ handleClose, data, images }) => {
  return (
    <>
      <div className="modal-content prizes">
        <motion.img variants={child} src={logo} alt="logo-citroen" className="logo" />

        <div className="modal-text text-center">
          <motion.span variants={child} className="quiz-label">
            {data.quizLabel}
          </motion.span>
          <motion.h1 variants={child} className="my-3 my-md-5">
            {data.prizeH1}
          </motion.h1>
          <motion.div variants={child} className="prize-wrapper row g-0">
            {data.prizes.map((prize, index) => (
              <div key={index} className="col-12 col-md-4 prize my-5">
                <img className="mb-5" src={images[prize.prizeImg]} alt="prize" />
                <p
                  className="prize-title"
                  dangerouslySetInnerHTML={{ __html: prize.prizeTitle }}
                ></p>
                <p className="prize-description">{prize.prizeContent}</p>
              </div>
            ))}
          </motion.div>
          <motion.div variants={child} className="">
            {' '}
            <button className="btn btn-out" onClick={handleClose}>
              {data.prizeCTA}
            </button>
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default Prizes;
