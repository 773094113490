// Import animation, motion
import { motion } from 'framer-motion';
import { containerVariants, childQuiz } from '../utils/animations';

// Import icons, images
import { RiCheckFill, RiCloseFill } from 'react-icons/ri';

const Question = ({ data, clickHandler, isFormSubmitted, is_correct, quizImages }) => {
  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="question"
    >
      <motion.div variants={childQuiz} initial="hidden" animate="visible" exit="exit">
        <div className="lead">
          <p className="text-center">{data.questionIntro}</p>
          <p className="fw-bold mb-1 py-2 px-2 text-center">{data.questionText}</p>
        </div>
        <div className="question-wrapper">
          <div className="question-image text-center">
            <img src={quizImages[data.questionImage]} alt="question" />
          </div>
          <div className="row g-0 answer-section">
            {data.answerOptions.map((answer, index) => (
              <div key={'answer_' + index} className="col-12 col-md-6 text-center">
                <button
                  id={'answer_' + index}
                  type="button"
                  className="btn btn-answer"
                  onClick={(e) => clickHandler(e, answer.is_correct, answer.answerText)}
                  disabled={isFormSubmitted === true && 'disabled'}
                >
                  {/* {answer.is_correct && (
                  <span
                    style={{ fontSize: '10px', top: '0', left: '0' }}
                    className="position-absolute "
                  >
                    C
                  </span>
                )} */}
                  {answer.answerText}

                  <span className="animation animation__active"></span>

                  {isFormSubmitted && (
                    <>
                      <motion.span
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: is_correct ? 0 : 0.6 }}
                        className="animation animation__correct  "
                      >
                        <motion.span
                          initial={{ opacity: 0, scale: 3 }}
                          animate={{ opacity: 1, scale: 1 }}
                          transition={{ duration: 1 }}
                        >
                          <RiCheckFill className="icon" />
                        </motion.span>
                      </motion.span>
                      <motion.span
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.3 }}
                        className="animation animation__wrong  "
                      >
                        <motion.span
                          initial={{ opacity: 0, scale: 3 }}
                          animate={{ opacity: 1, scale: 1 }}
                          transition={{ duration: 1 }}
                        >
                          <RiCloseFill className="icon" />
                        </motion.span>
                      </motion.span>
                    </>
                  )}
                </button>
              </div>
            ))}
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default Question;
