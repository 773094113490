// Import icons, images
import logo from '../assets/images/logo.svg';

const QuizStatus = ({
  data,
  totalQuestionsNum,
  currentQuestion,
  isFormSubmitted,
  questionTime,
  questionTimeLeft,
}) => {
  // Calculate the progress percentage
  const progress = ((questionTimeLeft / questionTime) * 100).toFixed(0);

  // Set the width percentage based on the progress value
  let progressBarStyle = {
    width: `${progress}%`,
    transition: 'width 1s linear',
    background: '#5B7F95',
  };

  // change style based on progress
  if (progress < 30 && !isFormSubmitted) {
    progressBarStyle = {
      ...progressBarStyle,
      background: '#DA291C',
    };
  }

  // console.log(questionTimeLeft);

  return (
    <>
      <div
        className="quiz-status mb-5 position-absolute top-0 start-0 end-0"
        style={{ display: 'flex', width: '100%' }}
      >
        <div style={{ flex: '1' }}>
          <div
            className="progress"
            style={{ background: '#D9D9D6', height: '24px', borderRadius: '0px', width: '100%' }}
          >
            <div
              className="progress-bar"
              role="progressbar"
              aria-valuenow={progress}
              aria-valuemin="0"
              aria-valuemax="100"
              style={progressBarStyle}
            >
              {/* {questionTimeLeft} */}
            </div>
          </div>
        </div>
        <div className="current-question mx-3 mt-1">
          {currentQuestion + 1} / {totalQuestionsNum}
        </div>
      </div>
      <div className="quiz-label position-absolute">
        <img src={logo} alt="logo-citroen" />
      </div>
    </>
  );
};

export default QuizStatus;
