// Import animation, motion
import { motion } from 'framer-motion';
import { childQuiz } from '../../utils/animations';

// Import icons, images
import logoWhite from '../../assets/images/citroen-logo.png';

const Survey = ({ handleSurveyClick, filterCurrentSurvey, data }) => {
  return (
    <>
      {' '}
      <div className="survey-label">
        <img src={logoWhite} alt="citroen-logo" />
        <p>{data.surveyLabel}</p>
      </div>
      <motion.div variants={childQuiz}>
        <p className="question-text px-2">{filterCurrentSurvey.surveyText}</p>
        <div className="row g-0 answer-section text-center">
          {filterCurrentSurvey.answerOptions.map((answerOption, index) => (
            <div key={index} className="col-12">
              <button
                id={`answer-${index}`}
                className="btn btn-survey"
                onClick={() => {
                  handleSurveyClick(
                    answerOption.path,
                    answerOption.answerTags,
                    answerOption.answerText,
                    index
                  );
                }}
              >
                <span>{answerOption.answerText}</span>
              </button>
            </div>
          ))}
        </div>
      </motion.div>
    </>
  );
};

export default Survey;
