// Import React stuff
import { useState } from 'react';

// Import redux stuff
import { useDispatch, useSelector } from 'react-redux';
import { resetUser } from '../../store/userSlice';

// Import animation, motion
import { resetGame, setShowIntro, setShowResult } from '../../store/gameSlice';

// Import helper functions
import { shareContent, copyLink } from '../../utils/helpers';

// Import images, icons
import logoEb from '../../assets/images/eb_logo.svg';
import logo from '../../assets/images/logo.svg';

// Import animation, motion
import { motion } from 'framer-motion';
import {
  containerVariants,
  child,
  childSocial,
  resultVariants,
  fadeInOut,
} from '../../utils/animations';

// Import components
import Score from '../../components/Score';

const Results = ({ quizData, data, images, mobile }) => {
  const dispatch = useDispatch();

  const questions = quizData?.questions;
  let totalQuestionsNum = questions?.length;

  const correctAnswers = useSelector((state) => state.games.correctAnswers);
  let correctAnswersNum = correctAnswers.filter((x) => x === true).length;

  // Calc success rate and points
  let successRate = Math.round((correctAnswersNum / totalQuestionsNum) * 100);
  const points = correctAnswersNum;

  // SEGMENTS - set quiz segments
  let segmentName = null;
  const segment = quizData?.segments.find(
    (segment) => successRate >= segment.segmentScoreMin && successRate <= segment.segmentScoreMax
  );
  if (segment) {
    segmentName = segment.segment;
  }

  // Comparison
  const allUsers = useSelector((state) => state.users.users);

  const calculateSuccessAll = (users) => {
    const successRatesAll = users.map((user) => {
      const totalQuestions = totalQuestionsNum;
      const correctAnswers = user.points;
      return Math.round((correctAnswers / totalQuestions) * 100);
    });

    return successRatesAll;
  };

  const successRatesAll = calculateSuccessAll(allUsers);

  // Calculate the percentage of games where the player's success rate is better
  const lessSuccessRates = successRatesAll.filter((rate) => rate < successRate);

  const betterThanPercent = Math.round((lessSuccessRates.length / successRatesAll.length) * 100);

  const userSegment = useSelector((state) => state.games.userSegment);

  // Play again
  const playAgain = () => {
    dispatch(setShowResult(false));
    dispatch(setShowIntro(true));
    window.scrollTo(0, 0);
    window.plausible('play_again');
    setTimeout(() => {
      dispatch(resetGame());
      dispatch(resetUser());
    }, 1000);
  };

  // Share results
  const title = '🚗💨 Avtomobilski virtuoz ali nedeljski voznik?';
  const url = 'https://citroen.brainylab.io/';
  const image = 'https://citroen.brainylab.io/social_share.png';
  const text = 'Pokaži svoje avtomobilske veščine v Turbo Avto Kvizu! 🏁 ';

  const onShareFacebook = () => {
    shareContent('facebook', title, url, text, image);
  };

  const onShareMessenger = () => {
    shareContent('messenger', title, url, text, image);
  };

  // Copy quiz url
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyLink = () => {
    copyLink(url);

    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 4000);
  };

  return (
    <motion.div
      variants={fadeInOut}
      initial="hidden"
      animate="visible"
      exit="exit"
      className={'results wrapper align-items-start align-items-md-center'}
    >
      <motion.div variants={child} className="quiz-label">
        <img src={logo} alt="logo-citroen" />
      </motion.div>
      <motion.img variants={child} className="eb-logo m-0" src={logoEb} alt="logo-eb" />
      <motion.div
        variants={resultVariants}
        initial="hidden"
        animate="visible"
        className="inner-wrapper text-center"
      >
        <>
          <div className="row g-0 text-start mb-2 mt-1 mb-md-4 mt-md-5">
            <motion.p variants={child} className="text-center">
              {data.resultLabel}
            </motion.p>
            <motion.h1 variants={child} className="text-center mb-4 mb-md-8">
              {segment?.segmentName}
            </motion.h1>
            <motion.div
              variants={child}
              className="text-center text-md-start col-12 col-md-5 segment-image"
            >
              <img src={images[segment.segmentImg]} alt={segmentName} />
            </motion.div>
            <div className="col-12 col-md-7 ps-md-5">
              <motion.p variants={child} className="segment-description">
                {segment?.segmentDescription}
              </motion.p>
              <div className="row g-0">
                <motion.div variants={child} className="resultbar-wrap col-4">
                  <Score points={points} percentage={successRate} />
                </motion.div>
                <motion.div variants={child} className="pt-3 pt-md-3 col-8 ps-3">
                  <p className="scorebar-descr">
                    Tvoj rezultat je boljši od{' '}
                    <span className="text-red fw-bold px-1">{betterThanPercent} %</span> drugih
                    uporabnikov, ki so rešili kviz.
                  </p>
                  <p className="scorebar-descr fw-bold">
                    Deli kviz in primerjaj rezultat s svojimi prijatelji.
                  </p>

                  <motion.div
                    variants={containerVariants}
                    initial="hidden"
                    animate="visible"
                    className="share-wrapper w-100"
                  >
                    <motion.button
                      variants={childSocial}
                      onClick={() => onShareFacebook()}
                      className="share-button btn"
                    >
                      <svg
                        width="35"
                        height="35"
                        viewBox="0 0 35 35"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M25.3153 2.63057L20.7132 2.625C16.2503 2.625 13.3674 5.58171 13.3674 10.1629V13.6361H8.75V19.9212H13.3674L13.3618 33.25H19.8223L19.8279 19.9212H25.126L25.1218 13.6375H19.8279V10.6905C19.8279 9.27341 20.1634 8.55651 22.0078 8.55651L25.3014 8.55511L25.3153 2.63057Z"
                          fill="#005BA2"
                        />
                      </svg>
                    </motion.button>

                    {mobile && (
                      <motion.button
                        variants={childSocial}
                        onClick={() => onShareMessenger()}
                        className="share-button btn"
                      >
                        <svg
                          width="35"
                          height="35"
                          viewBox="0 0 35 35"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            className="svg-path"
                            d="M17.5489 2.625C9.35337 2.625 2.625 9.01331 2.625 16.927C2.625 21.1438 4.57793 24.8893 7.59962 27.4981V33.25L13.1183 30.4906C14.5223 30.9133 15.9894 31.2291 17.5489 31.2291C25.7444 31.2291 32.4727 24.8408 32.4727 16.927C32.4727 9.01331 25.7444 2.625 17.5489 2.625ZM17.5489 5.11231C24.4618 5.11231 29.9854 10.4173 29.9854 16.927C29.9854 23.4368 24.4618 28.7418 17.5489 28.7418C16.0623 28.7418 14.6438 28.46 13.3127 28.0033L12.8074 27.8479L10.0869 29.2081V26.4099L9.62056 26.0213C6.8612 23.8449 5.11231 20.5851 5.11231 16.927C5.11231 10.4173 10.6359 5.11231 17.5489 5.11231ZM16.1497 13.0017L8.64895 20.93L15.3725 17.1991L18.948 21.0466L26.371 13.0017L19.803 16.6938L16.1497 13.0017Z"
                            fill="#005BA2"
                          />
                        </svg>
                      </motion.button>
                    )}
                    <motion.button
                      variants={childSocial}
                      onClick={handleCopyLink}
                      className={`share-button btn ${isCopied ? 'copied' : ''}`}
                    >
                      {isCopied ? (
                        <svg
                          width="35"
                          height="35"
                          viewBox="0 0 35 35"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            className="svg-path"
                            d="M2.15365 21.5705C1.4065 20.853 1.02029 19.8986 1.00076 18.9376C0.981488 17.9769 1.32915 17.0074 2.04635 16.2602C2.76304 15.5136 3.71748 15.1271 4.67818 15.1079C5.6394 15.0881 6.60843 15.4347 7.3561 16.1527L13.8112 22.364L26.6628 6.03867L27.1232 6.38998L26.6622 6.03711C26.699 5.98945 26.7406 5.94883 26.787 5.91575C27.5516 5.2535 28.5112 4.95453 29.4493 5.00558V5.00454L29.4951 5.0087C30.4386 5.0712 31.3602 5.49048 32.0407 6.24987C32.7329 7.02203 33.0467 8.00278 32.9944 8.96139H32.9954L32.9913 9.00722C32.9298 9.9338 32.5249 10.8393 31.791 11.5156L16.3677 30.2894L16.3688 30.2905C16.3399 30.3272 16.3073 30.3587 16.2719 30.3863C15.5498 31.0204 14.6378 31.3314 13.731 31.3158C12.8166 31.2999 11.9041 30.9522 11.1942 30.2699L2.15365 21.5705Z"
                            fill="#09AA59"
                          />
                        </svg>
                      ) : (
                        <svg
                          width="35"
                          height="35"
                          viewBox="0 0 35 35"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            className="svg-path"
                            d="M19.2815 4.8451C22.4138 1.71276 27.4923 1.71276 30.6247 4.8451C33.757 7.97743 33.757 13.0559 30.6247 16.1883L25.4687 21.3442C22.3364 24.4765 17.2579 24.4765 14.1255 21.3442C13.8356 21.0544 13.5726 20.7479 13.3363 20.4276C12.9835 19.9494 13.0823 19.2939 13.5026 18.8736C14.1925 18.1837 15.4087 18.4568 16.0771 19.1676C16.1134 19.2061 16.1503 19.2442 16.1879 19.2818C18.1811 21.2752 21.413 21.2752 23.4063 19.2818L28.5623 14.1259C30.5555 12.1326 30.5555 8.9008 28.5623 6.90749C26.569 4.9142 23.3372 4.9142 21.3438 6.90749L19.7971 8.45429C19.2276 9.02381 18.3042 9.02381 17.7347 8.45429C17.1651 7.88478 17.1651 6.96141 17.7347 6.3919L19.2815 4.8451Z"
                            fill="#0F0F0F"
                          />
                          <path
                            className="svg-path"
                            d="M10.0013 14.1257C12.9821 11.1449 17.83 11.0846 20.9265 13.7413C21.4843 14.2198 21.4389 15.0626 20.9192 15.5822C20.308 16.1934 19.2965 16.1005 18.5818 15.6143C16.6224 14.2812 13.8339 14.4179 12.0637 16.1881L6.90774 21.344C4.91444 23.3374 4.91444 26.5693 6.90774 28.5625C8.90105 30.5558 12.1328 30.5558 14.1261 28.5625L15.673 27.0157C16.2425 26.4462 17.1657 26.4462 17.7354 27.0157C18.3048 27.5853 18.3048 28.5085 17.7354 29.0782L16.1885 30.6249C13.0562 33.7572 7.97767 33.7572 4.84535 30.6249C1.71301 27.4925 1.71301 22.414 4.84535 19.2817L10.0013 14.1257Z"
                            fill="#0F0F0F"
                          />
                        </svg>
                      )}
                    </motion.button>
                  </motion.div>
                </motion.div>
              </div>
            </div>
          </div>

          <motion.div variants={child} className="mt-7 result-buttons">
            <a
              href={
                userSegment === 'LASTNIK - NEVČLANJEN'
                  ? 'https://www.citroen.si/vzdrzevanje/klub-zvestobe-mycitroen/fizicne-osebe.html'
                  : 'https://forms.citroen-slo.si/testna-voznja'
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <button
                type="button"
                className="btn btn-main"
                onClick={() => window.plausible('ctr', { props: { surveySegment: userSegment } })}
              >
                {data.resultCTA1}
              </button>
            </a>

            <button type="button" className="btn btn-again" onClick={playAgain}>
              {data.resultPlayAgain}
            </button>
          </motion.div>
        </>
      </motion.div>
    </motion.div>
  );
};

export default Results;
