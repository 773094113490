export const fadeInOut = {
  hidden: {
    opacity: 0.7,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 1,
    },
  },
  exit: {
    opacity: 0,
  },
};

export const fadeInOutSurvey = {
  hidden: {
    opacity: 0.5,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 1.5,
    },
  },
  exit: {
    opacity: 0.5,
    transition: {
      duration: 1,
    },
  },
};

export const containerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.35,
      ease: 'easeInOut',
    },
  },
  exit: {
    opacity: 0,
  },
};

export const resultVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.5,
      ease: 'easeInOut',
    },
  },
  exit: {
    opacity: 0,
  },
};

export const child = {
  hidden: { translateY: '-35px', opacity: 0 },
  visible: {
    translateY: 0,
    opacity: 1,
    transition: { duration: 1.15, type: 'spring' },
  },
};

export const childSocial = {
  hidden: { translateX: '-25px', opacity: 0 },
  visible: {
    translateX: 0,
    opacity: 1,
    transition: { duration: 0.8, type: 'spring' },
  },
};

export const modalDrop = {
  hidden: {
    y: '-100vh',
    opacity: 0,
  },
  visible: {
    y: '0',
    opacity: 1,
    transition: {
      duration: 0.1,
      type: 'spring',
      damping: 25,
      stiffness: 500,
      when: 'beforeChildren',
      staggerChildren: 0.35,
    },
  },
  exit: {
    y: '100vh',
    opacity: 0,
  },
};

// export const childQuiz = {
//   hidden: { translateX: '40px', opacity: 0 },
//   visible: {
//     translateX: 0,
//     opacity: 1,
//     transition: { duration: 1.15, type: 'spring' },
//   },
//   exit: { translateX: '-40px', opacity: 0 },
// };

export const childQuiz = {
  hidden: { x: '100%', opacity: 0 },
  visible: {
    x: 0,
    opacity: 1,
    transition: { duration: 1.15, type: 'spring' },
  },
  exit: { x: '-100%', opacity: 0 },
};
