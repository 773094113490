// Import React stuff
import React from 'react';
import { useMediaQuery } from '@react-hook/media-query';

// Import Redux stuff
import { useSelector } from 'react-redux';

// Import components
import Intro from './views/QuizIntro/Intro';
import Optin from './views/QuizOptin/Optin';
import Results from './views/QuizResults/Results';
import Quiz from './views/Quiz/Quiz';

// Import animation, motions
import { AnimatePresence, motion } from 'framer-motion';

// Import helper functions
import { importAll } from './utils/helpers';

// Import styles, icons, images
import './assets/styles/style.scss';

// Import data
import quizData from './data/quizData.json';
import quizText from './data/quizText.json';

function App() {
  //const dispatch = useDispatch();

  const showIntro = useSelector((state) => state.games.showIntro);
  const showResult = useSelector((state) => state.games.showResult);
  const showOptin = useSelector((state) => state.users.showOptin);
  const showSurvey = useSelector((state) => state.games.showSurvey);

  // Import images
  const images = importAll(require.context('./assets/images', false, /\.(png|jpe?g|svg|gif)$/));

  // Device viewport
  const mobile = useMediaQuery('only screen and (max-width: 767px)');

  return (
    <motion.div
      initial={{ opacity: 0.5 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0.5 }}
      transition={{ duration: 0.3 }}
      className={`main-container ${showSurvey ? 'survey-wrap' : ''}`}
    >
      <AnimatePresence mode="wait">
        {showOptin ? (
          <Optin key="optin" data={quizText} />
        ) : showResult ? (
          <Results
            key="result"
            quizData={quizData}
            data={quizText}
            images={images}
            mobile={mobile}
          />
        ) : showIntro ? (
          <Intro key="intro" data={quizText} images={images} />
        ) : (
          <Quiz key="quiz" quizData={quizData} data={quizText} showSurvey={showSurvey} />
        )}
      </AnimatePresence>
    </motion.div>
  );
}

export default App;
