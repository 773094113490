// Import react stuff
import { useState, useEffect } from 'react';

// Import animation, motion
import { AnimatePresence, motion } from 'framer-motion';
import { fadeInOut, containerVariants, fadeInOutSurvey } from '../../utils/animations';

// Import Redux stuff
import { useSelector, useDispatch } from 'react-redux';
import {
  setCorrectAnswers,
  setCurrentQuestion,
  setCurrentSurvey,
  setShowResult,
  setShowTrivia,
  setShowSurvey,
  setCurrentSurveyQuestion,
  setChosenProducts,
  setChosenAnswer,
  setSelectedAnswers,
  setUserSegment,
  questionTimeReducer,
  setQuestionTime,
} from '../../store/gameSlice';
import { setShowOptin } from '../../store/userSlice';

// Import components
import Survey from '../QuizSurvey/Survey';
import Trivia from '../QuizTrivia/Trivia';
import Question from '../../components/Question';
import QuizStatus from '../../components/QuizStatus';

// Import helper functions
import { importAll } from '../../utils/helpers';

const Quiz = ({ quizData, data, showSurvey }) => {
  const dispatch = useDispatch();
  const questions = quizData?.questions;

  // Import images
  const quizImages = importAll(
    require.context('../../assets/images/quiz', false, /\.(png|jpe?g|svg|gif)$/)
  );

  const questionTime = 30;

  // Is form submitted
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  // Global states - RTK Toolkit
  const currentQuestion = useSelector((state) => state.games.currentQuestion);
  //const correctAnswers = useSelector((state) => state.games.correctAnswers);
  const showTrivia = useSelector((state) => state.games.showTrivia);
  const currentSurvey = useSelector((state) => state.games.currentSurvey);
  const currentSurveyQuestion = useSelector((state) => state.games.currentSurveyQuestion);
  const chosenProducts = useSelector((state) => state.games.chosenProducts);
  const questionTimeLeft = useSelector((state) => state.games.time);

  // Score and success rate calculations
  //let correctAnswersNum = correctAnswers.filter((x) => x === true).length;
  let totalQuestionsNum = questions?.length;

  // On answer click
  const clickHandler = (e, is_correct, answerText) => {
    setIsFormSubmitted(true);

    // set time to zero
    dispatch(setQuestionTime(null));

    // Find correct answer(s)
    let correctAnswerIndices = questions[currentQuestion].answerOptions
      .map((answer, index) => (answer.is_correct ? index : -1))
      .filter((index) => index !== -1);

    // find correct answers, clicked answer and list all buttons to clear classes on next step
    let answerCorrectBtns = correctAnswerIndices.map((index) =>
      document.getElementById(`answer_${index}`)
    );
    let answerClickedBtn = document.getElementById(e.currentTarget.id);

    // add correct or wrong class
    answerClickedBtn.classList.add(is_correct ? 'correct' : 'wrong');
    answerCorrectBtns.forEach((btn) => btn.classList.add('correct'));

    // Add true/false to array and send to global state
    dispatch(setCorrectAnswers(is_correct));

    // Add answer text to array and send to global state
    dispatch(setSelectedAnswers(answerText));

    handleCheckForTrivia();
  };

  // Check if trivia is available for the current question
  const handleCheckForTrivia = () => {
    let trivia = questions[currentQuestion].trivias;
    if (trivia && (trivia.trivia_heading || trivia.trivia_text || trivia.trivia_image)) {
      // Show trivia and handle next question after a timeout
      setTimeout(() => {
        dispatch(setShowTrivia(true));
      }, 2000);
    } else {
      // Move to next question automatically after a timeout
      setTimeout(() => {
        handleNextQuestion();
      }, 2000);
    }
  };

  // Handle trivia button click
  const handleTriviaClick = () => {
    dispatch(setShowTrivia(false));
    handleNextQuestion();

    // Check for survey -- and display it, else move to next question
    const currentQuestionData = questions[currentQuestion];

    if (currentQuestionData.path === 'survey1') {
      // Show survey 1
      dispatch(setCurrentSurvey(quizData.survey[0].surveyId));
      dispatch(setShowSurvey(true));
    } else if (currentQuestionData.path === 'survey2') {
      // Show survey 2
      dispatch(setCurrentSurvey(quizData.survey[1].surveyId));
      dispatch(setShowSurvey(true));
    } else {
      setTimeout(() => {
        dispatch(setShowSurvey(false));
      }, 2000);
    }
  };

  const handleNextQuestion = () => {
    setIsFormSubmitted(false);
    handleResetTimer();

    // Remove classes for next questoon
    let answersAllButtons = document.querySelectorAll('.answer-section button');
    answersAllButtons.forEach(function (element) {
      element.classList.remove('correct');
      element.classList.remove('wrong');
    });

    // move to next question
    let nextQuestion = currentQuestion + 1;

    // Move to next question or to result screen
    if (nextQuestion < totalQuestionsNum) {
      dispatch(setCurrentQuestion(nextQuestion));
    } else if (nextQuestion === totalQuestionsNum) {
      //dispatch(scoreReducer(correctAnswersNum));

      const segment = defineUserSegment(chosenProducts);
      dispatch(setUserSegment(segment));

      dispatch(setShowOptin(true));
      dispatch(setShowResult(true));
    }
  };

  // Survey
  const handleSurveyClick = (path, answerTags, answerText, index) => {
    window.scrollTo(0, 0);

    const nextQuestion = path;

    // Plausible event - track selected survey answer
    window.plausible('poll', { props: { answer: answerText } });

    // Add product to array based on chosen answer
    dispatch(setChosenProducts([answerTags]));

    // // Add answer text to array
    dispatch(setChosenAnswer([answerText]));

    // Check whether next question exists otherwise show next question
    if (nextQuestion < quizData.survey[currentSurvey].content.length) {
      setTimeout(() => {
        dispatch(setCurrentSurveyQuestion(nextQuestion));
      }, 1000); //500
    } else {
      setTimeout(() => {
        dispatch(setCurrentSurveyQuestion(nextQuestion));
        dispatch(setShowSurvey(false));
      }, 1000);
    }
  };

  // Find object of current survey
  const filterCurrentSurveyQuestion = quizData.survey[currentSurvey].content.find(
    (obj) => obj.id === currentSurveyQuestion
  );

  // Set user segment based on survey's selected tags
  const defineUserSegment = () => {
    const owner = chosenProducts.includes('s1a1');
    const club = chosenProducts.includes('s2a1');

    if (owner && club) {
      return 'LASTNIK + VČLANJEN';
    } else if (owner && !club) {
      return 'LASTNIK - NEVČLANJEN';
    } else {
      return 'NELASTNIK';
    }
  };

  // Question timer
  const handleResetTimer = () => {
    // reset question time to initial value
    clearInterval(timer);
    dispatch(setQuestionTime(questionTime));
  };

  // time per question
  let timer;

  useEffect(() => {
    const timer = setInterval(() => {
      dispatch(questionTimeReducer());
    }, 1000);
    return () => clearInterval(timer);
  }, [dispatch, currentQuestion]);

  // When time's up and form is not submitted
  useEffect(() => {
    if (questionTimeLeft === 0 && !isFormSubmitted && !showSurvey) {
      handleCheckForTrivia();
    }
  }, [questionTimeLeft]);

  return (
    <AnimatePresence mode="wait">
      {showTrivia ? (
        <motion.div
          variants={fadeInOut}
          initial="hidden"
          animate="visible"
          exit="exit"
          className="quiz wrapper inner-wrapper"
        >
          <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            className="trivia"
          >
            <Trivia
              data={questions[currentQuestion]}
              handleTriviaClick={handleTriviaClick}
              quizImages={quizImages}
              text={data}
            />
          </motion.div>
        </motion.div>
      ) : showSurvey ? (
        <motion.div variants={fadeInOutSurvey} initial="hidden" animate="visible" exit="exit">
          <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            className="survey"
          >
            <Survey
              filterCurrentSurvey={filterCurrentSurveyQuestion}
              handleSurveyClick={handleSurveyClick}
              data={data}
            />
          </motion.div>
        </motion.div>
      ) : (
        <motion.div
          variants={fadeInOut}
          initial="hidden"
          animate="visible"
          exit="exit"
          className="quiz wrapper inner-wrapper"
        >
          <QuizStatus
            totalQuestionsNum={totalQuestionsNum}
            currentQuestion={currentQuestion}
            isFormSubmitted={isFormSubmitted}
            questionTimeLeft={questionTimeLeft}
            questionTime={questionTime}
            data={data}
          />
          <Question
            data={questions[currentQuestion]}
            clickHandler={clickHandler}
            isFormSubmitted={isFormSubmitted}
            quizImages={quizImages}
          />
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Quiz;
