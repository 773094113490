// Import React stuff
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';

// Import Redux stuff
import { Provider } from 'react-redux';
import store from './store/store';

// Import App
import App from './App';

// Web Vitals
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// Call reportWebVitals with a callback function to report performance metrics
reportWebVitals(console.log);
