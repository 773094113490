// Import react stuff
import { useState } from 'react';
import { useMediaQuery } from '@react-hook/media-query';

// Import redux stuff
import { useDispatch } from 'react-redux';
import { setShowIntro } from '../../store/gameSlice';
import { getAllUsers } from '../../store/actions';

// Import motion, animations
import { motion } from 'framer-motion';
import { fadeInOut, containerVariants, child } from '../../utils/animations';

// Import icons, images
import intro from '../../assets/images/citroen-intro.png';
import usp01 from '../../assets/images/usp01.svg';
import usp02 from '../../assets/images/usp02.svg';
import usp03 from '../../assets/images/usp03.svg';
import logo from '../../assets/images/logo.svg';
import logoEb from '../../assets/images/eb_logo.svg';

// Import components
import Prizes from '../../components/Prizes';

const Intro = ({ data, images }) => {
  const dispatch = useDispatch();

  const mobile = useMediaQuery('only screen and (max-width: 768px)');

  const startQuiz = () => {
    dispatch(setShowIntro(false));
    window.scrollTo(0, 0);
    dispatch(getAllUsers());
    window.plausible('start');
  };

  // Open/close prize modal
  const [openPrizeModal, setOpenPrizeModal] = useState(false);

  const closeModal = () => {
    setOpenPrizeModal(false);
    window.scrollTo(0, 0);
  };
  const openModal = () => {
    window.plausible('nagrade');
    window.scrollTo(0, 0);
    setOpenPrizeModal(true);
  };

  return (
    <>
      <motion.div
        variants={fadeInOut}
        initial="hidden"
        animate="visible"
        exit="exit"
        className="intro wrapper"
      >
        <motion.img variants={child} className="eb-logo m-0" src={logoEb} alt="logo-eb" />
        {openPrizeModal ? (
          <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            className="prizes"
            key={`prize`}
          >
            <Prizes handleClose={closeModal} data={data} images={images} />
          </motion.div>
        ) : (
          <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            className="d-flex row g-0 justify-content-center align-content-start align-content-md-center intro-wrap"
          >
            {mobile ? (
              <>
                <motion.img variants={child} src={logo} alt="logo-citroen" className="logo" />

                <motion.div variants={child} className="text-center col-12 px-2">
                  <h1 className="mb-4 mt-2">
                    <span dangerouslySetInnerHTML={{ __html: data.introPageH1 }}></span>
                  </h1>
                  <h3>{data.introPageH3}</h3>
                </motion.div>
                <motion.div variants={child} className="intro-img px-4 col-12">
                  <img src={intro} alt="intro-citroen" className="" />
                </motion.div>
                <motion.p
                  variants={child}
                  className="my-3 text-center px-4"
                  dangerouslySetInnerHTML={{ __html: data.introPageDescr }}
                ></motion.p>
              </>
            ) : (
              <>
                <motion.img variants={child} src={logo} alt="logo-citroen" className="logo" />
                <motion.div variants={child} className="col-md-5 intro-img p-2 p-lg-4">
                  <img src={intro} alt="intro-citroen" className="" />
                </motion.div>
                <motion.div variants={child} className="text-start col-7 p-2 p-lg-4">
                  <h1 className="mb-6 mt-4">
                    <span dangerouslySetInnerHTML={{ __html: data.introPageH1 }}></span>
                  </h1>
                  <h3>{data.introPageH3}</h3>
                  <p
                    className="mt-md-5"
                    dangerouslySetInnerHTML={{ __html: data.introPageDescr }}
                  ></p>
                </motion.div>
              </>
            )}

            <motion.div variants={child} className="row g-0">
              <div className="col-4 usps">
                <img className="icon" src={usp01} alt="usp-1" />
                <p>{data.usp1}</p>
              </div>
              <div className="col-4 usps">
                <img className="icon" src={usp02} alt="usp-2" />
                <p>{data.usp2}</p>
              </div>
              <div className="col-4 usps">
                <img className="icon" src={usp03} alt="usp-3" />
                <p>{data.usp3}</p>
              </div>
            </motion.div>

            <motion.div variants={child} className="mt-1 mt-md-5 text-center">
              <button className="btn btn-main" onClick={startQuiz}>
                {data.introPageCTA1}
              </button>
              <div className=" mt-0 mt-md-2 prize-link">
                <span
                  className="mb-1 me-2"
                  onClick={() => (openPrizeModal ? closeModal() : openModal())}
                >
                  {data.introPageCTA2}
                </span>
              </div>
              <div className="my-2 ">
                <a
                  href="/Pravila_in_pogoji_kviza-kviz_MC_PZ.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="conditions-link"
                >
                  <span className="">{data.introPageConditions}</span>
                </a>
              </div>
            </motion.div>
          </motion.div>
        )}
      </motion.div>
    </>
  );
};

export default Intro;
