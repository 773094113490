// Import animation, motion
import { motion } from 'framer-motion';
import { childQuiz } from '../../utils/animations';

// Import icons, images
import logo from '../../assets/images/logo.svg';

const Trivia = ({ data, handleTriviaClick, quizImages, text }) => {
  return (
    <>
      <div className="quiz-label">
        <img src={logo} alt="logo-citroen" />
      </div>
      <motion.div variants={childQuiz} initial="hidden" animate="visible" exit="exit">
        <div className="row g-0 d-flex align-items-md-center mb-3">
          <div className="col-12 col-md-6 text-center">
            <img src={quizImages[data.trivias.trivia_img]} alt="trivia" />
          </div>
          <div className="col-12 col-md-6 trivia-text mt-3 mt-md-0">
            <p className="trivia-intro px-5 px-md-5 px-lg-1">{data.trivias.trivia_intro}</p>
            <p
              className="px-5 px-md-5 px-lg-1 m-0"
              dangerouslySetInnerHTML={{ __html: data.trivias.trivia_text }}
            ></p>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-1 mt-md-5 mb-5 mb-md-0">
          <button type="button" className="btn btn-main" onClick={handleTriviaClick}>
            NAPREJ
          </button>
        </div>
      </motion.div>
    </>
  );
};

export default Trivia;
