import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const Score = ({ percentage, points }) => {
  return (
    <div className="result-bar">
      <div className="score-overlay">
        <p className="text-red point">{points}</p>
        <p className="result-points">
          {points === 1
            ? 'pravilen odgovor'
            : points === 2
            ? 'pravilna odgovora'
            : points >= 3 && points <= 4
            ? 'pravilni odgovori'
            : 'pravilnih odgovorov'}
        </p>
      </div>
      <CircularProgressbar
        value={percentage}
        styles={buildStyles({
          strokeLinecap: 'butt',
          pathColor: '#DA291C',
        })}
      />
    </div>
  );
};

export default Score;
