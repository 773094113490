// Import React stuff
import React, { useState } from 'react';

// Import actions, reducers
import { setShowOptin } from '../../store/userSlice';

// Import animation, motion
import { AnimatePresence, motion } from 'framer-motion';
import { fadeInOut, containerVariants, child } from '../../utils/animations';

// Import components
import { useDispatch, useSelector } from 'react-redux';
import { createUser } from '../../store/actions';

// Import helper functions
import { isValidEmail, validateForm } from '../../utils/helpers';
import { setShowResult } from '../../store/gameSlice';

const Optin = ({ data }) => {
  const dispatch = useDispatch();

  const quizAnswers = useSelector((state) => state.games.selectedAnswers);
  const surveyAnswers = useSelector((state) => state.games.chosenAnswer);
  const correctAnswers = useSelector((state) => state.games.correctAnswers);
  const userSegment = useSelector((state) => state.games.userSegment);

  const correctAnswersNum = correctAnswers.filter((x) => x === true).length;
  const points = correctAnswersNum;

  const [formData, setFormData] = useState({
    email: '',
    conditions: false,
    subscribe: false,
  });

  // Validate email
  const [unvalidEmail, setUnvalidEmail] = useState(false);

  // Errors
  const [formErrors, setFormErrors] = useState({
    email: false,
    conditions: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const fieldValue = type === 'checkbox' ? checked : value;
    setFormData((prevData) => ({
      ...prevData,
      [name]: fieldValue,
    }));

    if (name === 'email') {
      if (formErrors.email) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          email: false,
        }));
      }

      // Check if the email is valid
      const isEmailValid = isValidEmail(value);
      setUnvalidEmail(!isEmailValid);
    }

    if (name === 'conditions' && formErrors.conditions) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        conditions: false,
      }));
    }
  };

  const handleUser = () => {
    // Perform form validation
    const formErrors = validateForm(formData);

    if (Object.keys(formErrors).length > 0) {
      setFormErrors(formErrors);
    } else {
      const isEmailValid = isValidEmail(formData.email);
      if (formData.email.trim() !== '' && !isEmailValid) {
        setUnvalidEmail(true);
      } else {
        // Save the form data or dispatch an action
        console.log('Form data:', formData);

        if (formData.subscribe === true) {
          window.plausible('newsletter');
        }

        dispatch(
          createUser({
            formData,
            segment: userSegment,
            survey_step1: surveyAnswers[0],
            survey_step2: surveyAnswers[1],
            answers: JSON.stringify(quizAnswers),
            points,
          })
        )
          .then((action) => {
            const response = action.payload;
            if (response && response.status === 201) {
              // User created successfully
              setTimeout(() => {
                dispatch(setShowOptin(false));
                window.scrollTo(0, 0);
                dispatch(setShowResult(true));
                window.plausible('loged_user');
                window.plausible('result');
              }, 500);
            }
          })
          .catch((error) => {
            // Handle error
            console.log('Error:', error);
          });
      }
    }
  };

  const handleGuest = () => {
    const guestFormData = {
      email: 'guest@mail.com',
      subscribe: false,
      conditions: false,
    };

    dispatch(
      createUser({
        formData: guestFormData,
        segment: userSegment,
        survey_step1: surveyAnswers[0],
        survey_step2: surveyAnswers[1],
        answers: JSON.stringify(quizAnswers),
        points,
      })
    )
      .then((action) => {
        const response = action.payload;
        if (response && response.status === 201) {
          // Guest user created successfully
          setTimeout(() => {
            dispatch(setShowOptin(false));
            window.scrollTo(0, 0);
            dispatch(setShowResult(true));
            window.plausible('guest_user');
            window.plausible('result');
          }, 500);
        }
      })
      .catch((error) => {
        // Handle error
        console.log('Error:', error);
      });
  };

  return (
    <motion.div
      variants={fadeInOut}
      initial="hidden"
      animate="visible"
      exit="exit"
      className={'optin wrapper'}
    >
      <AnimatePresence mode="wait">
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
          className="inner-wrapper text-center"
        >
          {' '}
          <div className="optin-content">
            <div className="h-100" initial="hidden" animate="visible" exit="exit">
              <motion.div variants={child} className="m-0 p-0">
                <h1 className="mb-0 mb-md-2 px-md-4">{data.optinH1}</h1>
              </motion.div>
              <motion.div variants={child} className="optin-form">
                <div className="form-group">
                  <input
                    type="email"
                    className={`form-control my-4 ${
                      formErrors.email || unvalidEmail ? 'is-invalid' : ''
                    }`}
                    name="email"
                    placeholder="Elektronski naslov"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                {(formErrors.email || unvalidEmail) && (
                  <motion.div
                    key="error-message-input"
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 'auto' }}
                    transition={{
                      opacity: { duration: 0.5 },
                      height: { duration: 0.7 },
                    }}
                    style={{ color: '#B22C37', fontSize: '16px' }}
                    className="warning-text mt-2"
                  >
                    {formErrors.email
                      ? data.formErrorEmail
                      : unvalidEmail
                      ? data.formErrorUnvalidEmail
                      : null}
                  </motion.div>
                )}
                <div className="mt-3 text-start px-1 px-md-2">
                  <div className="form-check mb-3">
                    <input
                      className={`form-check-input ${formErrors.conditions ? 'is-invalid' : ''}`}
                      type="checkbox"
                      name="conditions"
                      id="conditions-check"
                      checked={formData.conditions}
                      onChange={handleChange}
                      required
                    />
                    <label
                      className={`form-check-label ${formErrors.conditions ? 'is-invalid' : ''}`}
                      htmlFor="conditions-check"
                    >
                      Strinjam se s{' '}
                      <a
                        href="/Pravila_in_pogoji_kviza-kviz_MC_PZ.pdf"
                        target="_blank"
                        rel="noreferrer noopener"
                        className="link"
                      >
                        pravili in pogoji
                      </a>{' '}
                      ter privolim v obdelavo osebnih podatkov, kot navedeno v teh pogoji ter želim
                      sodelovati v nagradni igri.
                    </label>
                  </div>

                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="subscribe"
                      id="subscribe-check"
                      checked={formData.subscribe}
                      onChange={handleChange}
                    />

                    <label className="form-check-label" htmlFor="subscribe-check">
                      {data.optinSubscribe}
                    </label>
                  </div>

                  {formErrors.conditions && (
                    <motion.div
                      key="error-message-input"
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: 'auto' }}
                      exit={{ opacity: 0, height: 'auto' }}
                      transition={{
                        opacity: { duration: 0.5 },
                        height: { duration: 0.7 },
                      }}
                      style={{ color: '#B22C37', fontSize: '16px' }}
                      className="warning-text mt-2 text-center"
                    >
                      {formErrors.conditions ? data.formErrorConditions : null}
                    </motion.div>
                  )}
                </div>
              </motion.div>

              <motion.div variants={child} className="optin-btn-wrapper">
                <button onClick={handleUser} className="btn btn-main">
                  {data.optinCTA1}
                </button>
                <button onClick={handleGuest} className="guest-result btn btn-sm mt-2">
                  {data.optinCTA2}
                </button>
              </motion.div>
            </div>
          </div>
        </motion.div>
      </AnimatePresence>
    </motion.div>
  );
};

export default Optin;
